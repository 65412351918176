import React from 'react';

import Navbar from '../components/navbar';
import NotFoundSection from '../components/not-found-section';
import Footer from '../components/footer';

import '../styles/index.css';

const About: React.FC = () => {
    return (
        <>
            <Navbar />
            <NotFoundSection />
            <Footer />
        </>
    );
};

export default About;
