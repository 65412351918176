import React from 'react';

import NotFoundCard from '../not-found-card';
import './not-found-section.css';

const NotFoundSection: React.FC = () => {
    return (
        <section className="not-found-section">
            <NotFoundCard />
        </section>
    );
};

export default NotFoundSection;
